<template>
	<SearchResultListItem
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
		:hide-second-row="!isMobile"
	>
		<template #row3>
			<div
				v-if="result.description"
				:class="[
					{'text-sm': isMobile},
					'text-left'
				]"
			>
				<div
					class="leading-5"
					:class="{ 'md:leading-6 line-clamp-2': !isMobile }"
					v-html="result.description"
				/>
			</div>
		</template>
		<span
			:class="[
				{'text-sm': isMobile},
				'truncate capitalize'
			]"
		>
			{{ result.category }}
		</span>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	}
}
</script>
