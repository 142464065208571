var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SearchResultListItem',{attrs:{"result":_vm.result,"is-mobile":_vm.isMobile,"list-type":_vm.result.listType,"hide-second-row":!_vm.isMobile},scopedSlots:_vm._u([{key:"row3",fn:function(){return [(_vm.result.description)?_c('div',{class:[
				{'text-sm': _vm.isMobile},
				'text-left'
			]},[_c('div',{staticClass:"leading-5",class:{ 'md:leading-6 line-clamp-2': !_vm.isMobile },domProps:{"innerHTML":_vm._s(_vm.result.description)}})]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('span',{class:[
			{'text-sm': _vm.isMobile},
			'truncate capitalize'
		]},[_vm._v("\n\t\t"+_vm._s(_vm.result.category)+"\n\t")])])
}
var staticRenderFns = []

export { render, staticRenderFns }